<template>
  <div class="Commenttag_I">
    <div class="headNavs">
      <div class="headTilte">评论标签</div>
      <div class="headDetails">说明：包含商品标签名称、状态及创建时间</div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>创建</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入标签名称">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelect"> 
        <el-table-column prop="tag_id" label="ID" width="80" align="center"></el-table-column>
        <el-table-column prop="tag_name" label="标签名称"  align="center"></el-table-column>
        <el-table-column prop="status_text" label="显示状态"  align="center"></el-table-column>
        <el-table-column prop="weigh" label="权重" align="center"></el-table-column>
        <el-table-column prop="createtime" label="创建时间"  align="center"></el-table-column>
        <el-table-column prop="updatetime" label="更新时间"  align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <div class="el_butto" >
              <el-button
                  type="text"
                  @click="HandleEdit(scope.$index,scope.row)"
              >编辑
              </el-button>
            </div>
            <div class="el_butto rere" >
              <el-button
                  type="text"
                  class="red"
                  @click="HandleDel(scope.$index, scope.row)"
              >删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>


    <div class="show" v-if="addPanel || editPanel">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <el-form class="EditNewForm">
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>标签名称：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.tag_name" placeholder="请填写标签名称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>状态：</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.status" placeholder="请选择是否启用">
                <el-option v-for="(item, index) in StatusList" :key="index" :label="item" :value="index"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>权重</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.weigh" placeholder="请填写权重"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="addPanel == true" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
              <div v-if="editPanel == true" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "index",
  data() {
    return {
      upload: 'https://rest-api.duxin365.com/admin/ajax/upload',
      heads: {token: ''},
      tableData:[],//总列表
      // 状态
      StatusList: [
        "隐藏",
        "正常"
      ],
      // 分页
      page: {
        currentPage: 1,
        pageSize: 10,
      },
      total: 0,
      // 列表数据
      Row: {
        tag_name: "",
        status: '',
        weigh: "",
      },
      //搜索参数
      search: {
        keyword: "",
      },
      addPanel: false,
      editPanel: false,
      editId: 0,  //删除id
      titleMath:'',

    }
  },
  mounted() {
    var that = this
    that.loadTable();
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
  },
  methods: {  
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/order/tag/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    //搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if (e == false) {
        that.search.keyword = ""
      }
      that.loadTable();
    },
    // 创建打开
    showAdd() {
      var that = this
      that.addPanel = true;
      that.titleMath = '添加'
      that.Row = {
        tag_name: "",
        status: "",
        weigh: 0
      };
    },
    //新增
    BtnAdd() {
      var that = this
      if (that.addPanel) {
        axios({
          method: 'post',
          url: '/admin/order/tag/add',
          data: {
            row: that.Row
          },
        }).then(function (res) {
          console.log(res.data);
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.addPanel = false
            that.loadTable();
          }
        })
      }
    },
    // 编辑保存
    BtnEdit() {
      var that = this
      if (that.editPanel) {
        axios({
          method: 'put',
          url: '/admin/order/tag/edit',
          data: {
            id: that.editId,
            row:{
              tag_name: that.Row.tag_name,
              status: that.Row.status,
              weigh: that.Row.weigh,
            }
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.editPanel = false
            that.loadTable();
          }
        })
      }
    },
    //获取编辑内容
    HandleEdit(e, data) {
      var that = this
      that.editPanel = true;
      that.titleMath = '添加'
      that.editId = data.tag_id
      axios.get('/admin/order/tag/read', {   // 详情
        params: {
          id: data.tag_id
        }
      }).then(function (res) {
        console.log(res.data.data);
        that.Row = {
          tag_name: res.data.data.row.tag_name,
          status: parseInt(res.data.data.row.status),
          weigh: res.data.data.row.weigh,
        }
      })
    },
    // 取消
    Close() {
      var that = this
      that.addPanel = false;
      that.editPanel = false;
    },
    //删除
    HandleDel(e, data) {
      var that = this
      var arr = []
      //主键ID
      arr.push(data.tag_id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        that.delTable(arr);
      })
    },
    delTable(arr) {
      var that = this
      axios({
        method: 'DELETE',
        url: '/admin/order/tag/del',
        data: {
          ids: arr
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.loadTable();
        }
      })
    },
    //勾选
    handleSelect(e) {
      var that = this
      var arr = []
      var acc = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          //主键ID
          arr.push(element.id)
          acc.push(element)
        }
        that.multipleSelection_id = arr
      }
      that.multipleSelection = acc
    },
    //分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;

      return y + "-" + MM + "-" + d
    },
  }
}
</script>
